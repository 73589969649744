<template>
  <div class="w-100">
    <tabs :options="{ useUrlFragment: false }">
      <tab v-for="(action, key) in rulesData" :key='key' :name='getActionName(key)'>
        <vue-json-pretty
          :path="'res'"
          :data="rulesData[key]"
        />
      </tab>
    </tabs>
  </div>

</template>

<script>
import {Tabs, Tab} from 'vue-tabs-component';
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: 'RulesItem',
  components: {
    Tabs,
    Tab,
    VueJsonPretty
  },
  props: {
    rulesData: Object
  },
  methods: {
    getActionName(action) {
      let name = ''
      let nameArr = action.split('.')[1]
      if (nameArr.length > 1) {
        name = action.split('.')[1]
        name = name[0].toUpperCase() + name.slice(1);
      }

      return name
    },
  },
}
</script>

<style lang="scss">
.condition-title, .text-dev, .constant {
  color: #57abe4;
}

.subCondition-title {
  color: #efb45c;
}

.sub-rules-items {
  color: black;
}

ul {
  padding: 0;
}

.tabs-component-tab {
  margin-bottom: -1px;
}

</style>